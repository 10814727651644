import React from "react";
import ApiUrl from "../../ApiUrl/ApiUrl";
import commonStyle from "../../Common/CommonStyle.module.css";
function Button(props) {
  const apiUrl = ApiUrl();
  const id = localStorage.getItem("accountId");
  const uniqueId = localStorage.getItem("uniqueId");
  let blob = new Blob([], { type: "image/png" });
  if (props.inputValue.headerTitle === undefined) {
    props.formValidation(true);
  }
  const handleSubmit = (props) => {
    const recent = props.recentUrl === undefined ? true : props.recentUrl;
    const cust =
      props.customfeatureurl === undefined ? true : props.customfeatureurl;
    const planRoomUrlData =
      props.planRoomUrl === undefined ? true : props.planRoomUrl;
    if (props.inputValue.headerTitle) {
      if (
        (recent == true || props.recentCheck == false) &&
        (cust == true || props.customFeatureCheck == false) &&
        (planRoomUrlData == true || props.planRoomLinkData == "") &&
        (props.menuUrl == true || props.menuLength == true)
      ) {
        props.loadingActive(true);
        var formdata = new FormData();
        const Data = {
          fk_user_id: id,
          theme_color:
            props.inputValue.themeColor == undefined
              ? props.apiData.theme_color
              : props.inputValue.themeColor,
          business_name:
            props.inputValue.headerTitle == undefined
              ? props.apiData.business_name
              : props.inputValue.headerTitle,
          menus:
            props.inputValue.menus == undefined
              ? props.apiData.menus
              : props.inputValue.menus,
          show_search:
            props.inputValue.show_search == undefined
              ? props.apiData.show_search == null
                ? false
                : props.apiData.show_search
              : props.inputValue.show_search,
          show_menu:
            props.inputValue.menu == undefined
              ? props.apiData.show_menu == null
                ? false
                : props.apiData.show_menu
              : props.inputValue.menu,
          address:
            props.inputValue.footerAddress == undefined
              ? props.apiData.address
              : props.inputValue.footerAddress,
          email:
            props.inputValue.gmail == undefined
              ? props.apiData.email
              : props.inputValue.gmail,
          phone:
            props.inputValue.phone == undefined
              ? props.apiData.phone
              : props.inputValue.phone,
          facebook:
            props.inputValue.facebook == undefined
              ? props.apiData.facebook
              : props.inputValue.facebook,
          instagram:
            props.inputValue.instagram == undefined
              ? props.apiData.instagram
              : props.inputValue.instagram,
          linked_in:
            props.inputValue.linkedin == undefined
              ? props.apiData.linked_in
              : props.inputValue.linkedin,
          twitter:
            props.inputValue.twitter == undefined
              ? props.apiData.twitter
              : props.inputValue.twitter,
          about:
            props.inputValue.about == undefined
              ? props.apiData.about
              : JSON.stringify({
                  aboutHtml: props.inputValue.about.toString("html"),
                }),
          custom_feature:
            props.inputValue.custom_feature == undefined
              ? props.apiData.custom_feature
              : props.inputValue.custom_feature,
          testimonial:
            props.inputValue.testimonial == undefined
              ? props.apiData.testimonial
              : JSON.stringify(
                  props.inputValue.testimonial.map((testimonial) =>
                    testimonial.toString("html")
                  )
                ),
          plan_room:
            props.inputValue.plan_room == undefined
              ? props.apiData.plan_room
              : props.inputValue.plan_room,
          privacy_policy:
            props.inputValue.privacyPolicy == undefined
              ? props.apiData.privacy_policy
              : props.inputValue.privacyPolicy,
          service_policy:
            props.inputValue.instagram == undefined
              ? props.apiData.service_policy
              : props.inputValue.instagram,
          sitemap_link:
            props.inputValue.sitemap_link == undefined
              ? props.apiData.sitemap_link
              : props.inputValue.sitemap_link,
          show_about:
            props.inputValue.show_about == undefined
              ? props.apiData.show_about == null
                ? false
                : props.apiData.show_about
              : props.inputValue.show_about,
          show_features:
            props.inputValue.show_features == undefined
              ? props.apiData.show_features == null
                ? false
                : props.apiData.show_features
              : props.inputValue.show_features,
          show_testimonial:
            props.inputValue.show_testimonial == undefined
              ? props.apiData.show_testimonial == null
                ? false
                : props.apiData.show_testimonial
              : props.inputValue.show_testimonial,
          show_plan_room:
            props.inputValue.show_plan_room == undefined
              ? props.apiData.show_plan_room == null
                ? false
                : props.apiData.show_plan_room
              : props.inputValue.show_plan_room,
          show_feature_application:
            props.inputValue.show_feature_application == undefined
              ? props.apiData.show_feature_application == null
                ? false
                : props.apiData.show_feature_application
              : props.inputValue.show_feature_application,
          show_feature_request:
            props.inputValue.show_feature_request == undefined
              ? props.apiData.show_feature_request == null
                ? false
                : props.apiData.show_feature_request
              : props.inputValue.show_feature_request,
          show_feature_feedback:
            props.inputValue.show_feature_feedback == undefined
              ? props.apiData.show_feature_feedback == null
                ? false
                : props.apiData.show_feature_feedback
              : props.inputValue.show_feature_feedback,
          show_feature_custom:
            props.inputValue.show_feature_custom == undefined
              ? props.apiData.show_feature_custom == null
                ? false
                : props.apiData.show_feature_custom
              : props.inputValue.show_feature_custom,
          type: "l",
          show_feature_news:
            props.inputValue.show_feature_news == undefined
              ? props.apiData.show_feature_news == null
                ? false
                : props.apiData.show_feature_news
              : props.inputValue.show_feature_news,
          recentNewsLink:
            props.inputValue.recentNewsLink == undefined
              ? props.apiData.recentNewsLink
              : props.inputValue.recentNewsLink,
        };
        if (
          props.apiData.logo_aws &&
          props.inputValue.headerLogo == undefined
        ) {
          Data.logo_update = props.apiData.logo_aws;
        }
        if (
          props.apiData.background_img &&
          props.inputValue.sectionFourImage == undefined
        ) {
          Data.bgImgUpdate = props.apiData.background_img;
        }
        formdata.append("jsonData", JSON.stringify(Data));
        formdata.append(
          "logo_aws",
          props.inputValue.headerLogo == undefined
            ? blob
            : props.inputValue.headerLogo,
          props.inputValue.headerLogo ? props.inputValue.headerLogo.name : ""
        );

        formdata.append(
          "background_img",
          props.inputValue.sectionFourImage == undefined
            ? blob
            : props.inputValue.sectionFourImage,
          props.inputValue.sectionFourImage
            ? props.inputValue.sectionFourImage.name
            : ""
        );
        const requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };
        fetch(`${apiUrl.apiUrl}/portal/setting`, requestOptions)
          .then((response) => response.json())
          .then((res) => {
            props.loadingActive(false);
            props.popup(true);
            props.popup(res.message);
          });
      }
    }
  };
  const handlePreview = (props) => {
    const recent = props.recentUrl === undefined ? true : props.recentUrl;
    const cust =
      props.customfeatureurl === undefined ? true : props.customfeatureurl;
    const planRoomUrlData =
      props.planRoomUrl === undefined ? true : props.planRoomUrl;
    if (props.inputValue.headerTitle) {
      if (
        (recent == true || props.recentCheck == false) &&
        (cust == true || props.customFeatureCheck == false) &&
        (planRoomUrlData == true || props.planRoomLinkData == "") &&
        (props.menuUrl == true || props.menuLength == true)
      ) {
        var formdata = new FormData();
        const Data = {
          fk_user_id: id,
          theme_color:
            props.inputValue.themeColor == undefined
              ? props.apiData.theme_color
              : props.inputValue.themeColor,
          business_name:
            props.inputValue.headerTitle == undefined
              ? props.apiData.business_name
              : props.inputValue.headerTitle,
          menus:
            props.inputValue.menus == undefined
              ? props.apiData.menus
              : props.inputValue.menus,
          show_search:
            props.inputValue.show_search == undefined
              ? props.apiData.show_search == null
                ? false
                : props.apiData.show_search
              : props.inputValue.show_search,
          show_menu:
            props.inputValue.menu == undefined
              ? props.apiData.show_menu == null
                ? false
                : props.apiData.show_menu
              : props.inputValue.menu,
          address:
            props.inputValue.footerAddress == undefined
              ? props.apiData.address
              : props.inputValue.footerAddress,
          email:
            props.inputValue.gmail == undefined
              ? props.apiData.email
              : props.inputValue.gmail,
          phone:
            props.inputValue.phone == undefined
              ? props.apiData.phone
              : props.inputValue.phone,
          facebook:
            props.inputValue.facebook == undefined
              ? props.apiData.facebook
              : props.inputValue.facebook,
          instagram:
            props.inputValue.instagram == undefined
              ? props.apiData.instagram
              : props.inputValue.instagram,
          linked_in:
            props.inputValue.linkedin == undefined
              ? props.apiData.linked_in
              : props.inputValue.linkedin,
          twitter:
            props.inputValue.twitter == undefined
              ? props.apiData.twitter
              : props.inputValue.twitter,
          about:
            props.inputValue.about == undefined
              ? props.apiData.about
              : JSON.stringify({ aboutHtml: props.inputValue.about.innerHTML }),
          custom_feature:
            props.inputValue.custom_feature == undefined
              ? props.apiData.custom_feature
              : props.inputValue.custom_feature,
          testimonial:
            props.inputValue.testimonial == undefined
              ? props.apiData.testimonial
              : JSON.stringify({ testimonial: props.inputValue.testimonial }),
          plan_room:
            props.inputValue.plan_room == undefined
              ? props.apiData.plan_room
              : props.inputValue.plan_room,
          privacy_policy:
            props.inputValue.privacyPolicy == undefined
              ? props.apiData.privacy_policy
              : props.inputValue.privacyPolicy,
          service_policy:
            props.inputValue.instagram == undefined
              ? props.apiData.service_policy
              : props.inputValue.instagram,
          sitemap_link:
            props.inputValue.sitemap_link == undefined
              ? props.apiData.sitemap_link
              : props.inputValue.sitemap_link,
          show_about:
            props.inputValue.show_about == undefined
              ? props.apiData.show_about == null
                ? false
                : props.apiData.show_about
              : props.inputValue.show_about,
          show_features:
            props.inputValue.show_features == undefined
              ? props.apiData.show_features == null
                ? false
                : props.apiData.show_features
              : props.inputValue.show_features,
          show_testimonial:
            props.inputValue.show_testimonial == undefined
              ? props.apiData.show_testimonial == null
                ? false
                : props.apiData.show_testimonial
              : props.inputValue.show_testimonial,
          show_plan_room:
            props.inputValue.show_plan_room == undefined
              ? props.apiData.show_plan_room == null
                ? false
                : props.apiData.show_plan_room
              : props.inputValue.show_plan_room,
          show_feature_application:
            props.inputValue.show_feature_application == undefined
              ? props.apiData.show_feature_application == null
                ? false
                : props.apiData.show_feature_application
              : props.inputValue.show_feature_application,
          show_feature_request:
            props.inputValue.show_feature_request == undefined
              ? props.apiData.show_feature_request == null
                ? false
                : props.apiData.show_feature_request
              : props.inputValue.show_feature_request,
          show_feature_feedback:
            props.inputValue.show_feature_feedback == undefined
              ? props.apiData.show_feature_feedback == null
                ? false
                : props.apiData.show_feature_feedback
              : props.inputValue.show_feature_feedback,
          show_feature_custom:
            props.inputValue.show_feature_custom == undefined
              ? props.apiData.show_feature_custom == null
                ? false
                : props.apiData.show_feature_custom
              : props.inputValue.show_feature_custom,
          type: "p",
          show_feature_news:
            props.inputValue.show_feature_news == undefined
              ? props.apiData.show_feature_news == null
                ? false
                : props.apiData.show_feature_news
              : props.inputValue.show_feature_news,
          recentNewsLink:
            props.inputValue.recentNewsLink == undefined
              ? props.apiData.recentNewsLink
              : props.inputValue.recentNewsLink,
        };
        if (
          props.apiData.logo_aws &&
          props.inputValue.headerLogo == undefined
        ) {
          Data.logo_update = props.apiData.logo_aws;
        }
        if (
          props.apiData.background_img &&
          props.inputValue.sectionFourImage == undefined
        ) {
          Data.bgImgUpdate = props.apiData.background_img;
        }
        formdata.append("jsonData", JSON.stringify(Data));
        formdata.append(
          "logo_aws",
          props.inputValue.headerLogo == undefined
            ? blob
            : props.inputValue.headerLogo,
          props.inputValue.headerLogo ? props.inputValue.headerLogo.name : ""
        );
        formdata.append(
          "background_img",
          props.inputValue.sectionFourImage == undefined
            ? blob
            : props.inputValue.sectionFourImage,
          props.inputValue.sectionFourImage
            ? props.inputValue.sectionFourImage.name
            : ""
        );
        const requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };
        fetch(`${apiUrl.apiUrl}/portal/setting`, requestOptions)
          .then((response) => response.json())
          .then((res) => {});

        window.open(`https://${uniqueId}${apiUrl.previewUrl}`, "_blank");
      }
    }
  };

  return (
    <div className={commonStyle.submit_preview}>
      {props.name == "Publish" ? (
        <button
          onClick={() => handleSubmit(props)}
          className={commonStyle.upload_button}
        >
          {props.name}
        </button>
      ) : (
        <button
          onClick={() => handlePreview(props)}
          className={commonStyle.upload_button}
        >
          {props.name}
        </button>
      )}
    </div>
  );
}
export default Button;
