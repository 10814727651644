import React, { useEffect, useState } from "react";
import commonStyle from "../../Common/CommonStyle.module.css";
import "quill/dist/quill.snow.css";
import RichTextEditor from "react-rte";

function AboutUs(props) {
  const [open, setOpen] = useState(false);
  const [aboutUs, setAboutUs] = useState(RichTextEditor.createEmptyValue());
  const toolbarConfig = {
    display: ["INLINE_STYLE_BUTTONS"],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
  };
  const handleOpen = (val) => {
    setOpen(!open);
    props.data({ show_about: !open });
  };
  useEffect(() => {
    setOpen(props.api.show_about);
  }, [props.api.show_about]);
  useEffect(() => {
    if (props.api.about) {
      const sectionOneData = JSON.parse(props.api.about);
      const aboutUsata = RichTextEditor.createValueFromString(
        sectionOneData.aboutHtml,
        "html"
      );
      setAboutUs(aboutUsata);
    }
  }, [props.api]);
  const onChange = (value) => {
    setAboutUs(value);
    props.data({ about: aboutUs });
  };
  return (
    <div className={commonStyle.card}>
      <div className={commonStyle.card_border}>
        <div className={`${commonStyle.row} ${commonStyle.row_space}`}>
          <h4>About Us</h4>
          {open ? (
            <div onClick={handleOpen} className={commonStyle.toggle_on}>
              <div className={commonStyle.toggle_right}></div>
            </div>
          ) : (
            <div onClick={handleOpen} className={commonStyle.toggle_off}>
              <div className={commonStyle.toggle_left}></div>
            </div>
          )}
        </div>
        {open ? (
          <div
            className={`${commonStyle.sub_bg} ${commonStyle.border} ${commonStyle.center_div}`}
          >
            <RichTextEditor
              toolbarConfig={toolbarConfig}
              value={aboutUs}
              onChange={(e) => onChange(e)}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
export default AboutUs;
