import React, { useEffect, useState } from "react";
import commonStyle from '../../Common/CommonStyle.module.css';
import style from '../Menus/Menus.module.css';

function Menus(props) {
    const [open, setOpen] = useState(false);
    const [menu, setMenu] = useState([{ title: "", link: "" }]);
    const [urlOnchangeValidation, setOnchangeUrlValidation] = useState(false);
    const [allMenu, setAllMenu] = useState([]);
    const handleOpen = () => {
        setOpen(!open);
        props.data({ menu: !open })
    }
    useEffect(() => {
        setOpen(props.api.show_menu)
    }, [props.api.show_menu])
    useEffect(() => {
        if (props.api.menus) {
            const menuData = JSON.parse(props.api.menus);
            setMenu(menuData)
        }
    }, [props.api])

    const handleMenuChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...menu];
        list[index][name] = value;
        setMenu(list);
        props.data({ menus: JSON.stringify(menu) })
        setAllMenu((prev ) => {
            let arr = prev
            arr[index] = urlChecker(list[index].link)
            props.errorUrl({ menuUrl: arr.every(e=>e===true||e===null) })
            return arr;
        })
                setOnchangeUrlValidation((prev) => !prev)
            };
    const handleMenuAddClick = () => {
        setMenu([...menu, { title: "", link: "" }]);
    };
    const handleRemoveClick = index => {
        const list = [...menu];
        list.splice(index, 1);
        setMenu(list);
        props.data({ menus: JSON.stringify(list) })
        if (menu.length == 1) {
            setOpen(false)
            props.data({ menu: !open })
        }
        allMenu.splice(index, 1)
        props.errorUrl({ menuLength: urlOnchangeValidation === urlOnchangeValidation,menuUrl: allMenu.every(e=>e===true||e===null) })
    };
    const urlChecker = (str) => {
        const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        if (regexp.test(str)) {
            return true;
        }
        else {
            return false;
        }
    }

    const showErrorMessage = (onchnageMenu) => {
        if (!urlChecker(onchnageMenu) && onchnageMenu !== "") {
            return <label className={commonStyle.error}>Enter valid menu url</label>
        }
    }
    return (
        <div className={commonStyle.card}>
            <div className={commonStyle.card_border}>
                <div className={`${commonStyle.row} ${commonStyle.row_space}`}>
                    <h4>Menus</h4>
                    {open ?
                        <div onClick={handleOpen} className={commonStyle.toggle_on}>
                            <div className={commonStyle.toggle_right}></div>
                        </div> :
                        <div onClick={handleOpen} className={commonStyle.toggle_off}>
                            <div className={commonStyle.toggle_left}></div>
                        </div>
                    }
                </div>
                {open ?
                    <div className={`${commonStyle.mobile_row_space} ${commonStyle.border} ${commonStyle.padding_bottom}`}>
                        <div className={`${style.menu_width} ${style.menu_size}`}>
                            <div className={`${commonStyle.mobile_row_space} ${commonStyle.padding_bottom} ${commonStyle.center}`}>
                                <div className={style.menu_size}>
                                    <div>
                                        <div >
                                            <div >
                                                <div className={`${commonStyle.row} ${style.align_right}`}>
                                                     <div className={style.add_button}>
                                                        <button className={`${style.add_menu} ${style.menu_size}`} onClick={() => handleMenuAddClick('menu')}>Add</button>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={style.spacing}>

                                                        {
                                                            menu.map((x, i) => {
                                                                return (
                                                                    <div className={` ${commonStyle.padding_bottom}`} key={i}>
                                                                        <div className={style.row}>
                                                                            <div className={style.menu_size}>
                                                                                <div>
                                                                                    <label>Menu Title</label>
                                                                                </div>
                                                                                <input
                                                                                    name="title"
                                                                                    placeholder="Enter Menu Title"
                                                                                    value={x.title}
                                                                                    onChange={e => handleMenuChange(e, i)}
                                                                                    className={style.menu_input}
                                                                                />
                                                                            </div>
                                                                            <div className={`${style.menu_size} ${style.pl}`}>
                                                                                <div>
                                                                                    <label>Menu Link</label>
                                                                                </div>
                                                                                <input
                                                                                    className={style.menu_input}
                                                                                    name="link"
                                                                                    placeholder="Enter Menu link"
                                                                                    value={x.link}
                                                                                    onChange={e => handleMenuChange(e, i)}
                                                                                />
                                                                                {showErrorMessage(menu[i].link)}
                                                                            </div>
                                                                            <div className={style.remove_btn}>
                                                                                {menu.length !== 0 && <button
                                                                                    className={`${commonStyle.upload_button} ${style.remove_width}`}
                                                                                    onClick={() => handleRemoveClick(i)}>Remove</button>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        }

                                                    </div>


                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div> </div>
                    : ""}
            </div>
        </div>
    );
}
export default Menus;