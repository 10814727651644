import commonStyle from '../../Common/CommonStyle.module.css'
const ImageUpload = (props) => {
  const onChangePicture = e => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        props.details({ src: reader.result, file: e.target.files[0] });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  return (
    <div>
      <label className={commonStyle.label}>Upload image<input id="profilePic" type="file" accept="image/png,image/jpeg" className={commonStyle.uploadFile} onChange={onChangePicture} />
      </label>
    </div>
  );
};
export default ImageUpload;