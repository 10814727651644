import React, { useEffect, useState } from "react";
import ColorTheme from "./ColorTheme/ColorTheme";
import PortalFooter from "./PortalFooter/PortalFooter";
import PortalHeader from "./PortalHeader/PortalHeader";
import PreviewAndPublish from "./PreviewAndPublish/PreviewAndPublish";
import PlanRoom from "./PlanRoom/PlanRoom";
import Feataure from "./Feataure/Feataure";
import Header from "./Header/Header";
import Menus from "./Menus/Menus";
import GetApi from "../Api/GetApi";
import PopUp from "./PopUp/PopUp";
import Search from "./Search/Search";
import commonStyle from '../Common/CommonStyle.module.css';
import LoadingSpinner from "../Loading/LoadingSpinner/LoadingSpinner";
import Backdrop from "../Loading/Backdrop/Backdrop";
import Testimonial from "./Testimonial/Testimonial";
import AboutUs from "./AboutUs/AboutUs";
import history from "../Route/history";
function Portal(props) {
  const [formData, setFormData] = useState({});
  const [popup, setPopUp] = useState(false);
  const [loading, setLoader] = useState(true);
  const [status, setStatus] = useState();
  const [error, setError] = useState();
  const [recentUrl, setRecentUrl] = useState(true);
  const [recentCheck, setRecentCheck] = useState(false);
  const [customFeatureUrl, setCustomFeatureUrl] = useState(true);
  const [customFeatureCheck, setCustomFeatureCheck] = useState(false);
  const [menuUrlVal, setMenuUrlVal] = useState(true);
  const [planRoomUrl, setPlanRoomVal] = useState(true);
  const [validation,setValidation]=useState(false)
  const [menuLength,setMenuLength]=useState(false)
  const getFormData = (param) => {
    setFormData((previous) => {
      return { ...previous, ...param };
    });
  };
  const handleLoad = (param) => {
    if (param == true) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  };
  useEffect(() => {
    handleLoad();
  }, []);
 useEffect  (()=>{
   const loginStatus=localStorage.getItem("accountId")
   if(loginStatus==null){
    history.push("/");
    history.go();
   }
 })
  const newUserLogic = () => {
    if (!props.newUserForm) {
      return GetApi();
    }
    return "";
  };
  const getSignStstus=localStorage.getItem("loginStatus")
  const getApiResponseFromLocal = GetApi();
  const getApiResponse =props.apiData;

  const OpenPopUp = (param) => {
    if (loading == false && param) {
      setPopUp(true);
      setStatus(param);
    }
  };
  useEffect(() => {
    OpenPopUp();
  }, []);
  const closeProps = () => {
    setPopUp(false);
  };
  const inputValidation = (param) => {
    setError(param);
  };
  const urlValidation=(param)=>{
    setCustomFeatureCheck(param.customCheck)
    setRecentCheck(param.recentCheck)
    
    if(param.custom_url!==undefined){
      setCustomFeatureUrl(param.custom_url)
    }
    if(param.recentNewsUrl!==undefined){
      setRecentUrl(param.recentNewsUrl)
    }
    if(param.menuUrl!==undefined){
      setMenuUrlVal(param.menuUrl)
    }
    setMenuLength(param.menuLength)
    if(param.plan_room_link!==undefined){
      setPlanRoomVal(param.plan_room_link)
    }
  }
  const formValidation=(param)=>{
    setValidation(param)
  }
  return (
    <div >
      <div>
         {loading?
     <div>
     <LoadingSpinner/>
     <Backdrop/>
     </div>:""}
          <Header/>
          <div className={commonStyle.portal_padding}>
          <ColorTheme data={getFormData} api={getSignStstus=="Y"&&!getApiResponse?getApiResponseFromLocal:getApiResponse} />
          <PortalHeader
            errorMessage={error}
            data={getFormData}
            api={getSignStstus=="Y"&&!getApiResponse?getApiResponseFromLocal:getApiResponse}
            validation={validation}
          />
          <Menus errorUrl={urlValidation} errorMessage={error} data={getFormData} api={getSignStstus=="Y"&&!getApiResponse?getApiResponseFromLocal:getApiResponse} />
          <Search data={getFormData} api={getSignStstus=="Y"&&!getApiResponse?getApiResponseFromLocal:getApiResponse} />
          <AboutUs
            errorMessage={error}
            data={getFormData}
            api={getSignStstus=="Y"&&!getApiResponse?getApiResponseFromLocal:getApiResponse}
          />
          {/* Now this feature is not needed */}
          {/* <Feataure
            errorUrl={urlValidation}
            errorMessage={error}
            data={getFormData}
            api={getSignStstus=="Y"&&!getApiResponse?getApiResponseFromLocal:getApiResponse}
          /> */}
          <Testimonial data={getFormData} api={getSignStstus=="Y"&&!getApiResponse?getApiResponseFromLocal:getApiResponse} />
          <PlanRoom
            errorMessage={error}
            data={getFormData}
            api={getSignStstus=="Y"&&!getApiResponse?getApiResponseFromLocal:getApiResponse}
            errorUrl={urlValidation}
          />
          <PortalFooter errorUrl={urlValidation} data={getFormData} api={getSignStstus=="Y"&&!getApiResponse?getApiResponseFromLocal:getApiResponse} />
          <PreviewAndPublish
            data={formData}
            api={getSignStstus=="Y"&&!getApiResponse?getApiResponseFromLocal:getApiResponse}
            token={props.token}
            loading={handleLoad}
            popupStatus={OpenPopUp}
            validation={inputValidation}
            recentUrl={recentUrl}
            recentCheck={recentCheck}
            customFeature={customFeatureUrl}
            customFeatureCheck={customFeatureCheck}
            menuUrlVal={menuUrlVal}
            planRoomUrl={planRoomUrl}
            menuLength={menuLength}
            formValidation={formValidation}
          />
          {popup && <PopUp close={closeProps}  message={status} />}
          </div>
      </div>
    </div>
  );
}
export default Portal;
