import React from "react";
import commonStyle from '../../Common/CommonStyle.module.css';

const TextArea = React.forwardRef((props, ref) => {
    return (
        <div className={commonStyle.title}>
            {props.value ? (
                props.type == "address" ?
                    <textarea ref={ref} value={props.value} onChange={props.onChange} placeholder={props.placeHolder} type={props.type} className={commonStyle.ad}></textarea> :
                    <textarea ref={ref} value={props.value} onChange={props.onChange} placeholder={props.placeHolder} type={props.type} className={commonStyle.ad}></textarea>) :
                props.type == "address" ?
                    <textarea ref={ref} onChange={props.onChange} placeholder={props.placeHolder} type={props.type} className={commonStyle.ad}></textarea> :
                    <textarea ref={ref} onChange={props.onChange} placeholder={props.placeHolder} type={props.type} className={commonStyle.ad}></textarea>
            }
        </div>
    );
});
export default TextArea;