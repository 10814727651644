import React, { useState } from "react";
import style from "./ColorTheme.module.css";
import commonStyle from '../../Common/CommonStyle.module.css';
function ColorTheme(props) {
    const [selectedColor, setSelectedColor] = useState('');
    const [activeColor, setActiveColor] = useState(false);
    const handleColor = (color) => {
        if (color == props.api.theme_color) {
            setActiveColor(false)
        } else {
            setActiveColor(true)
        }
        setSelectedColor(color);
        props.data({ themeColor: color })
    }
    return (
        <div className={commonStyle.card}>
            <div className={commonStyle.card_border}>
                <div className={`${commonStyle.row} ${commonStyle.row_space}`}>
                    <h4>Theme Color</h4>
                </div>
                <div className={`${commonStyle.row} ${commonStyle.padding_top} ${commonStyle.border} ${commonStyle.padding_left}  ${commonStyle.padding_bottom}`}>

                    <div onClick={() => handleColor("orange")} className={!activeColor && props.api.theme_color == 'orange' || selectedColor == 'orange' ? style.choosed_color : style.choosed_transperant} tabIndex="1">
                        <div className={style.orange}></div>
                    </div>
                    <div className={style.theme_color}>
                        <div onClick={() => handleColor("green")} className={!activeColor && props.api.theme_color == 'green' || selectedColor == 'green' ? style.choosed_color : style.choosed_transperant} tabIndex="1">
                            <div className={style.green}></div>
                        </div>
                    </div>
                    <div className={style.theme_color}>

                        <div onClick={() => handleColor("blue")} className={!activeColor && props.api.theme_color == 'blue' || selectedColor == 'blue' ? style.choosed_color : style.choosed_transperant} tabIndex="1">
                            <div className={style.blue}></div>
                        </div>
                    </div>
                    <div className={style.theme_color}>

                        <div id="violet" onClick={() => handleColor("violet")} className={!activeColor && props.api.theme_color == 'violet' || selectedColor == 'violet' ? style.choosed_color : style.choosed_transperant} tabIndex="1">
                            <div className={style.violet}></div>
                        </div>
                    </div>
                    <div className={style.theme_color}>

                        <div id="red" onClick={() => handleColor("red")} className={!activeColor && props.api.theme_color == 'red' || selectedColor == 'red' ? style.choosed_color : style.choosed_transperant} tabIndex="1">
                            <div className={style.red}></div>
                        </div>
                    </div>
                    <div className={style.theme_color}>

                        <div id="grey" onClick={() => handleColor("grey")} className={!activeColor && props.api.theme_color == 'grey' || selectedColor == 'grey' ? style.choosed_color : style.choosed_transperant} tabIndex="1">
                            <div className={style.grey}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ColorTheme;