import React, { useEffect, useRef, useState } from "react";
import commonStyle from "../../Common/CommonStyle.module.css";
import Input from "../../Common/CommonComponents/Input";
import ImageUpload from "../../Common/CommonComponents/ImageUpload";
import ApiUrl from "../../ApiUrl/ApiUrl";
import style from "./PlanRoom.module.css";
import UplaodImage from "../../../Images/upload.jpeg";
function SectionFour(props) {
  const [open, setOpen] = useState(false);
  const [displayImage, setDisplayImage] = useState();
  const planRoomTitleRef = useRef();
  const planRoomLinkRef = useRef();
  const planRoomDescRef = useRef();
  const [planRoomTitle, setPlanRoomTitle] = useState();
  const [planRoomDesc, setPlanRoomDesc] = useState();
  const [planRoomLink, setPlanRoomLink] = useState();
  const [urlValidation, setUrlValidation] = useState();
  const apiUrl = ApiUrl();
  useEffect(() => {
    if (props.api.plan_room) {
      const planRoomData = JSON.parse(props.api.plan_room);
      setPlanRoomTitle(planRoomData.heading);
      setPlanRoomDesc(planRoomData.subHeading);
      setPlanRoomLink(planRoomData.buttonText);
    }
  }, [props.api, props.api.show_plan_room]);
  const handleOpen = () => {
    setOpen(!open);
    props.data({ show_plan_room: !open });
  };
  useEffect(() => {
    setOpen(props.api.show_plan_room);
  }, [props.api.show_plan_room]);
  const handleChanges = () => {
    setPlanRoomTitle(planRoomTitleRef.current.value);
    setPlanRoomDesc(planRoomDescRef.current.value);
    setPlanRoomLink(planRoomLinkRef.current.value);
    props.data({
      plan_room: JSON.stringify({
        heading: planRoomTitleRef.current.value,
        subHeading: planRoomDescRef.current.value,
        buttonText: planRoomLinkRef.current.value,
      }),
    });
    if(planRoomLinkRef){
      setUrlValidation(urlChecker(planRoomLinkRef.current.value))
      props.errorUrl({plan_room_link:urlChecker(planRoomLinkRef.current.value),plan_room_link_data:planRoomLinkRef.current.value})
    }
  };
  const hanleImage = (imgProps) => {
    setDisplayImage(imgProps.src);
    props.data({ sectionFourImage: imgProps.file });
  };
 const urlChecker=(str)=>
{
 const regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        if (regexp.test(str))
        {
          return true;
        }
        else
        {
          return false;
        }
}
  return (
    <div className={commonStyle.card}>
      <div className={commonStyle.card_border}>
        <div className={`${commonStyle.row} ${commonStyle.row_space}`}>
          <h4>Plan Room</h4>
          {open ? (
            <div onClick={handleOpen} className={commonStyle.toggle_on}>
              <div className={commonStyle.toggle_right}></div>
            </div>
          ) : (
            <div onClick={handleOpen} className={commonStyle.toggle_off}>
              <div className={commonStyle.toggle_left}></div>
            </div>
          )}
        </div>
        {open ? (
          <div>
            <div
              className={`${commonStyle.mobile_row} ${commonStyle.border} ${commonStyle.padding_bottom}`}
            >
              <div className={commonStyle.added_list_width}>
                <label>
                  PlanRoom Banner Image
                </label>
                <div className={commonStyle.row_div}>
                  <div className={commonStyle.banner_header}>
                    {displayImage ? (
                      <img
                        className={commonStyle.banner_image}
                        src={displayImage}
                        alt="banner"
                      />
                    ) : (
                      props.api.background_img!=="File does not exist"?
                      <img
                        className={commonStyle.banner_image}
                        src={
                          props.api.background_img}
                        alt="banner"
                      />:<img
                      className={commonStyle.banner_image}
                      src={UplaodImage}
                      alt="banner"
                    />
                    )}
                  </div>
                  <div className={commonStyle.pl}>
                    <ImageUpload details={hanleImage} />
                    <p
                      className={`${commonStyle.p} ${commonStyle.image_label_plan_room}`}
                    >
                      Recommended image size 1200 x 800 pixel
                    </p>
                  </div>
                </div>
                <div
                  className={`${commonStyle.title} ${commonStyle.padding_top}`}
                >
                  <label>Plan Room Title</label>
                  <Input
                    placeHolder="Title"
                    label="Display Section Four Title?"
                    ref={planRoomTitleRef}
                    type="text"
                    onChange={handleChanges}
                    value={planRoomTitle}
                    style="pr"
                  />
                </div>
                {props.errorMessag ? (
                  <label className={` ${commonStyle.error}`}>
                    Business Name should not be Empty
                  </label>
                ) : (
                  ""
                )}
                <div
                  className={`${style.input_padding_left} ${commonStyle.title}`}
                >
                  <label>Plan Room Description</label>
                  <Input
                    placeHolder="Desctiption"
                    label="Display Section Four desctiption Link?"
                    ref={planRoomDescRef}
                    type="text"
                    onChange={handleChanges}
                    value={planRoomDesc}
                    style="pr"
                  />
                </div>
                <div className={commonStyle.title}>
                  <label>Plan Room Link</label>
                  <Input
                    placeHolder="Link"
                    label="Display Section Four Link?"
                    ref={planRoomLinkRef}
                    type="textarea"
                    onChange={handleChanges}
                    value={planRoomLink}
                    style="pr"
                  />
                  {urlValidation==false&&planRoomLinkRef.current.value?
                  <label className={commonStyle.error}>Enter valid plan room url</label>:""}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
export default SectionFour;
