import React, { useEffect, useState } from "react";
import "./App.css";
import Portal from "./Components/Portal/Portal";
import ErrorPop from "./Components/Portal/PopUp/ErrorPopUp";
import LoadingSpinner from "./Components/Loading/LoadingSpinner/LoadingSpinner";
import Backdrop from "./Components/Loading/Backdrop/Backdrop";
import GetApi from "./Components/Api/GetApi";
import SignIn from "./Components/SignIn/SignIn";

function App() {
  const [loading,setloading]=useState(false);
  GetApi(setloading);
  const [loginErrorStatus,setLoginErrorStatus]=useState('');
  const [errorPopup, setPopUp] = useState(false);
  const [login, setLogin] = useState(false);
  const [loginData, setLoginData] = useState(localStorage.getItem("loginStatus"));
  const loginError=(param)=>{
      setPopUp(true)
      setLoginErrorStatus(param)
    
    if(!param)
    {
      setPopUp(false)
    }

  }
  const closeProps = (param) => {
    setPopUp(param)
  }
  useEffect(()=>{
    if(loginData=="Y")
    {
    setLogin(true)
    }else{
      setLogin(false)
    }
  },[loginData])
  return (
   <div className="App">
     {loading?
     <div>
     <LoadingSpinner/>
     <Backdrop/>
     </div>:""}
      <div className="main">
          {login?
          <Portal/>:
          <SignIn setloading={setloading} error={loginError}/>}
      {errorPopup && <ErrorPop errorMessage={loginErrorStatus} close={closeProps}  />}
    </div>
   </div>
  );
}

export default App;
