import React, { useEffect, useState } from "react";
import style from "./SignIn.module.css";
import ApiUrl from "../ApiUrl/ApiUrl";
import Portal from "../Portal/Portal";
import LoadingSpinner from "../Loading/LoadingSpinner/LoadingSpinner";
import Backdrop from "../Loading/Backdrop/Backdrop";
function SignIn(props) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [portalData, setPortalData] = useState("");
  const [loading, setloading] = useState(false);
  const [logined, setLogined] = useState(false);
  const apiUrl = ApiUrl();

  useEffect(() => {
    const loginStatusData = localStorage.getItem("loginStatus");
    setLogined(loginStatusData);
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (username == undefined || password == undefined) {
      props.error("Invalid");
      setloading(false);
    }
    if (username && password) {
      setloading(true);
      var myHeaders = new Headers();
      myHeaders.append("Email", username);
      myHeaders.append("Api-Key", password);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${apiUrl.apiUrl}/portal/login`, requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (result.user_id === 0) {
            setloading(false);
            throw new Error(result.message);
          }
          if (result.user_id > 0 && result.portal !== null) {
            setPortalData(result.portal);
            localStorage.setItem("loginStatus", "Y");
            localStorage.setItem("accountId", result.user_id);
            localStorage.setItem("uniqueId", result.uuid);
            setloading(false);
            return;
          }
          setloading(false);
        })
        .finally(() => {})
        .catch((error) => props.error(error.message));
    }
  };

  return (
    <>
      {loading ? (
        <div>
          <LoadingSpinner />
          <Backdrop />
        </div>
      ) : (
        ""
      )}
      {logined == "Y" ? <Portal apiData={portalData} /> : ""}
      <div className={style.container_center}>
        {logined == null ? (
          <div className={style.vertical_align}>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className={style.space_top}>
                <input
                  placeholder="Email"
                  className={style.input}
                  type="text"
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>
              <div className={style.space_top}>
                <input
                  placeholder="Api Key"
                  className={style.input}
                  type="text"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className={`${style.space_top}`}>
                <button className={style.button} type="submit">
                  Login
                </button>
              </div>
            </form>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
export default SignIn;
