import React, { useEffect, useRef, useState } from "react";
import style from "../PortalHeader/PortalHeader.module.css";
import commonStyle from "../../Common/CommonStyle.module.css";
import Input from "../../Common/CommonComponents/Input";
import ImageUpload from "../../Common/CommonComponents/ImageUpload";
import UplaodImage from "../../../Images/upload.jpeg";

function PortalHeader(props) {
  const [displayImage, setDisplayImage] = useState();
  const [businessTitle, setBusinessTitle] = useState('');
  const titleRef = useRef(0);
  const handleChanges = () => {
    setBusinessTitle(titleRef.current.value);
    
  };
  useEffect(()=>{
    props.data({
      headerTitle: businessTitle,
    });
  },[businessTitle])
  const hanleImage = (imgProps) => {
    setDisplayImage(imgProps.src);
    props.data({ headerLogo: imgProps.file });
  };
  useEffect(() => {
    setBusinessTitle(props.api.business_name);
  }, [props.api.business_name]);
  return (
    <div className={commonStyle.card}>
      <div className={commonStyle.card_border}>
        <div className={`${commonStyle.row} ${commonStyle.row_space}`}>
          <h4>Business Information</h4>
        </div>
        <div
          className={`${commonStyle.mobile_row} ${commonStyle.border} ${commonStyle.padding_bottom}`}
        >
          <div>
            <label className={` ${style.padding_left}`}>Business Logo</label>
            <div
              className={`${style.pl} ${commonStyle.padding_bottom}`}
            >
              <div>
                <div className={commonStyle.row_div}>
                  <div className={commonStyle.logo_header}>
                    {displayImage ? (
                      <img
                        className={commonStyle.logo_border}
                        src={displayImage}
                        alt="logo"
                      />
                    ) : (
                      props.api.logo_aws!=="File does not exist"?
                      <img
                        className={commonStyle.logo_border}
                        src={props.api.logo_aws}
                        alt="logo"
                      />:
                      <img
                        className={commonStyle.logo_border}
                        src={UplaodImage}
                        alt="logo"
                      />
                    )}
                  </div>
                  <div
                    className={` ${commonStyle.pl} ${commonStyle.image_label}`}
                  >
                    <ImageUpload details={hanleImage} type="header" />
                    <p className={commonStyle.p}>
                      Recommended image size 300 x 300 pixel
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <label className={`${style.pl}  ${commonStyle.required} `}>
              Business Name
            </label>
            <div className={`${style.pl}`}>
              <Input
                placeHolder="Business Name"
                label="Display Business Name In Portal?"
                ref={titleRef}
                type="text"
                onChange={handleChanges}
                value={businessTitle}
                style="bn"
              />
              {props.validation&&businessTitle<=0?
              <label className={commonStyle.error}>Business Name Should not Empty</label>:""}
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
}
export default PortalHeader;
