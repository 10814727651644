import React, { useEffect, useRef, useState } from "react";
import commonStyle from "../../Common/CommonStyle.module.css";
import Input from "../../Common/CommonComponents/Input";
import TextArea from "../../Common/CommonComponents/TextArea";
import style from "./PortalFooter.module.css";
import instagramImg from "../../../Images/instagram.png";
import facebookImg from "../../../Images/facebook.png";
import twitterImg from "../../../Images/twitter.png";
import linkedInImg from "../../../Images/linkedin.png";
import mailImg from "../../../Images/mail.png";
import phoneImg from "../../../Images/phone.png";
function PortalFooter(props) {
  const footerAddressRef = useRef();
  const instagramRef = useRef();
  const facebookRef = useRef();
  const twitterRef = useRef();
  const linkedinRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const privacypolicyRef = useRef();
  const siteMapRef = useRef();
  const [address, setAddress] = useState();
  const [privacy, setPrivacy] = useState();
  const [sitemap, setSitemap] = useState();
  const [instagram, setInstagram] = useState();
  const [facebook, setFacebook] = useState();
  const [twitter, setTwitter] = useState();
  const [linkedIn, setLinkedIn] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [privacyValidation, setPrivacyValidation] = useState();
  const [sitemapValidation, setSitemapValidation] = useState();
  const [instagramValidation, setInstagramValidation] = useState();
  const [facebookValidation, setFacebookValidation] = useState();
  const [twitterValidation, setTwitterValidation] = useState();
  const [linkedInValidation, setLinkedInValidation] = useState();
  const [emailValidation, setEmailValidation] = useState();
  const handleChanges = () => {
    setAddress(footerAddressRef.current.value);
    setPrivacy(privacypolicyRef.current.value);
    setSitemap(siteMapRef.current.value);
    setInstagram(instagramRef.current.value);
    setFacebook(facebookRef.current.value);
    setTwitter(twitterRef.current.value);
    setLinkedIn(linkedinRef.current.value);
    setEmail(emailRef.current.value);
    const regExp = /[a-zA-Z]/g;
    if (regExp.test(phoneRef.current.value)) {
      console.log("contains letter");
      setPhone((prev) => prev);
    } else {
      console.log("only numbers");
      setPhone(phoneRef.current.value);
    }
    props.data({
      footerAddress: footerAddressRef.current.value,
      instagram: instagramRef.current.value,
      facebook: facebookRef.current.value,
      twitter: twitterRef.current.value,
      linkedin: linkedinRef.current.value,
      gmail: emailRef.current.value,
      phone: phoneRef.current.value,
      privacyPolicy: privacypolicyRef.current.value,
      sitemap_link: siteMapRef.current.value,
    });
    if (privacypolicyRef) {
      setPrivacyValidation(urlChecker(privacypolicyRef.current.value));
      props.errorUrl({
        privacyUrl: urlChecker(privacypolicyRef.current.value),
      });
    }
    if (siteMapRef.current.value) {
      setSitemapValidation(urlChecker(siteMapRef.current.value));
      props.errorUrl({ developerUrl: urlChecker(siteMapRef.current.value) });
    }
    if (instagramRef.current.value) {
      setInstagramValidation(urlChecker(instagramRef.current.value));
      props.errorUrl({ instagramUrl: urlChecker(instagramRef.current.value) });
    }
    if (facebookRef.current.value) {
      setFacebookValidation(urlChecker(facebookRef.current.value));
      props.errorUrl({ facebookUrl: urlChecker(facebookRef.current.value) });
    }
    if (twitterRef.current.value) {
      setTwitterValidation(urlChecker(twitterRef.current.value));
      props.errorUrl({ twitterUrl: urlChecker(twitterRef.current.value) });
    }
    if (linkedinRef.current.value) {
      setLinkedInValidation(urlChecker(linkedinRef.current.value));
      props.errorUrl({ linkedinUrl: urlChecker(linkedinRef.current.value) });
    }
    if (emailRef.current.value) {
      setEmailValidation(urlChecker(emailRef.current.value));
      props.errorUrl({ emailUrl: urlChecker(emailRef.current.value) });
    }
  };

  useEffect(() => {
    setAddress(props.api.address);
    setPrivacy(props.api.privacy_policy);
    setSitemap(props.api.sitemap_link);
    setInstagram(props.api.instagram);
    setFacebook(props.api.facebook);
    setTwitter(props.api.twitter);
    setLinkedIn(props.api.linked_in);
    setEmail(props.api.email);
    setPhone(props.api.phone);
  }, [props.api]);
  const urlChecker = (str) => {
    const regexp =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (regexp.test(str)) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className={commonStyle.card}>
      <div className={commonStyle.card_border}>
        <div className={`${commonStyle.row} ${commonStyle.row_space}`}>
          <h4>Footer</h4>
        </div>
        <div className={`${style.border}`}>
          <div className={style.padding_left}>
            <div>
              <div className={`${style.border} ${commonStyle.start_div}`}>
                <div>
                  <label>Address</label>
                  <TextArea
                    placeHolder="Address"
                    label="Display Footer Address?"
                    ref={footerAddressRef}
                    type="address"
                    onChange={handleChanges}
                    value={address}
                    style="fo"
                  />
                </div>
                <div className={` ${commonStyle.margin_top}`}>
                  <label>Privacy Policy Url</label>
                  <Input
                    placeHolder="Privacy Policy url"
                    label="Display Privacy Policy url?"
                    ref={privacypolicyRef}
                    type="text"
                    onChange={handleChanges}
                    value={privacy}
                    style="fo"
                  />
                  {privacyValidation == false &&
                  privacypolicyRef.current.value ? (
                    <label className={`${commonStyle.error}`}>
                      Enter valid privacy policy url
                    </label>
                  ) : (
                    ""
                  )}
                </div>
                <div className={` ${commonStyle.margin_top}`}>
                  <label>Sitemap</label>
                  <Input
                    placeHolder="Site Map url"
                    label="Display Site Map url?"
                    ref={siteMapRef}
                    type="text"
                    onChange={handleChanges}
                    value={sitemap}
                    style="fo"
                  />
                  {sitemapValidation == false && siteMapRef.current.value ? (
                    <label className={commonStyle.error}>
                      Enter valid sitemap url
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div>
              <div className={style.border}>
                <label>Social Media</label>
                <div className={`${style.row} `}>
                  <div className={commonStyle.social_spacing}>
                    <img src={instagramImg} height="50px" width="50px" />
                  </div>
                  <div>
                    <Input
                      placeHolder="Instagram url"
                      label="Display Instagram url?"
                      ref={instagramRef}
                      type="text"
                      onChange={handleChanges}
                      value={instagram}
                      style="sm"
                    />
                    {instagramValidation == false &&
                    instagramRef.current.value ? (
                      <label className={commonStyle.error}>
                        Enter valid instagram url
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className={`${style.row} `}>
                  <div className={commonStyle.social_spacing}>
                    <img src={facebookImg} height="50px" width="50px" />
                  </div>
                  <div>
                    <Input
                      placeHolder="Facebook url"
                      label="Display Facebook url?"
                      ref={facebookRef}
                      type="text"
                      onChange={handleChanges}
                      value={facebook}
                      style="sm"
                    />
                    {facebookValidation == false &&
                    facebookRef.current.value ? (
                      <label className={commonStyle.error}>
                        Enter valid facebook url
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className={`${style.row} `}>
                  <div className={commonStyle.social_spacing}>
                    <img src={twitterImg} height="50px" width="50px" />
                  </div>
                  <div>
                    <Input
                      placeHolder="Twitter url"
                      label="Display Twitter url?"
                      ref={twitterRef}
                      type="text"
                      onChange={handleChanges}
                      value={twitter}
                      style="sm"
                    />
                    {twitterValidation == false && twitterRef.current.value ? (
                      <label className={commonStyle.error}>
                        Enter valid twitter url
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className={`${style.row} `}>
                  <div className={commonStyle.social_spacing}>
                    <img src={linkedInImg} height="50px" width="50px" />
                  </div>
                  <div>
                    <Input
                      placeHolder="LinkedIn url"
                      label="Display LinkedIn url?"
                      ref={linkedinRef}
                      type="text"
                      onChange={handleChanges}
                      value={linkedIn}
                      style="sm"
                    />
                    {linkedInValidation == false &&
                    linkedinRef.current.value ? (
                      <label className={commonStyle.error}>
                        Enter valid linkedIn url
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className={`${style.row} `}>
                  <div className={commonStyle.social_spacing}>
                    <img src={mailImg} height="50px" width="50px" />
                  </div>
                  <Input
                    placeHolder="Email"
                    label="Display Email?"
                    ref={emailRef}
                    type="text"
                    onChange={handleChanges}
                    value={email}
                    style="sm"
                  />
                </div>
                <div className={`${style.row} `}>
                  <div className={commonStyle.social_spacing}>
                    <img src={phoneImg} height="50px" width="50px" />
                  </div>
                  <Input
                    placeHolder="Phone Number"
                    label="Display Phone Number?"
                    ref={phoneRef}
                    type="text"
                    onChange={handleChanges}
                    value={phone}
                    style="sm"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PortalFooter;
