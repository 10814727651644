import React, { useEffect, useState } from "react";
import commonStyle from '../../Common/CommonStyle.module.css';
import style from "./Testimonial.module.css";
import RichTextEditor from 'react-rte';
function Testimonial(props) {
    const [open, setOpen] = useState(false);
    const [testimonial, setTestimonial] = useState([]);

    useEffect(() => {
        if (props.api.testimonial) {
            const sectionOneData = JSON.parse(props.api.testimonial);
            let testimonialValue = sectionOneData.map(el => RichTextEditor.createValueFromString(el, "html"))

            setTestimonial(testimonialValue);
        }
    }, [props.api])

    const toolbarConfig = {
        display: ['INLINE_STYLE_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD' },
            { label: 'Italic', style: 'ITALIC' },
            { label: 'Underline', style: 'UNDERLINE' }
        ],
    };
    const handleOpen = () => {
        setOpen(!open)
        props.data({ show_testimonial: !open })
    }
    useEffect(() => {
        if (props.api.show_testimonial)
            setOpen(props.api.show_testimonial)
    }, [props.api])
    const handleMenuAddClick = () => {
        setTestimonial([...testimonial, RichTextEditor.createEmptyValue()]);
    };
    const handleRemoveClick = index => {
        const list = [...testimonial];
        list.splice(index, 1);
        setTestimonial(list);
        props.data({ testimonial: list })
        if (testimonial.length == 1) {
            setOpen(false)
            props.data({ show_testimonial: !open })
        }
    };
    const onChange = (value, i) => {
        const list = [...testimonial];
        list[i] = value;
        setTestimonial(list);
        props.data({ testimonial: testimonial })
    };
    return (
        <div className={commonStyle.card}>
            <div className={commonStyle.card_border}>
                <div className={`${commonStyle.row} ${commonStyle.row_space}`}>
                    <h4>Testimonial</h4>
                    {open ?
                        <div onClick={handleOpen} className={commonStyle.toggle_on}>
                            <div className={commonStyle.toggle_right}></div>
                        </div> :
                        <div onClick={handleOpen} className={commonStyle.toggle_off}>
                            <div className={commonStyle.toggle_left}></div>
                        </div>
                    }
                </div>
                {open ?
                    <div className={`${commonStyle.sub_bg} ${commonStyle.border}`}>
                        <div className={style.add_alignment}>
                            <button className={`${style.add_button} ${style.add_width} `} onClick={() => handleMenuAddClick('testimonial')}>Add</button>
                        </div>
                        {
                            testimonial.map((x, i) => {
                                return (
                                    <div className={` ${commonStyle.padding_bottom}`} key={i}>
                                        <div>
                                            <div className={`${style.testimonial_alignment} ${commonStyle.center_div}`}>
                                                <RichTextEditor toolbarConfig={toolbarConfig} value={x} onChange={(value) => onChange(value, i)} className={style.text_editor_width} />
                                                <div className={style.remove_padding}>
                                                    {testimonial.length !== 0 && <button
                                                        className={`${style.button}`}
                                                        onClick={() => handleRemoveClick(i)}>Remove</button>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                    : ""}
            </div>
        </div>
    );
}
export default Testimonial;