import React from "react";
import style from "./PreviewAndPublish.module.css";
import commonStyle from '../../Common/CommonStyle.module.css';
import Button from "../../Common/CommonComponents/Button";
function PreviewAndPublish(props) {
    return (
        <div className={commonStyle.card}>
            <div>
                <div className={`${commonStyle.row} ${commonStyle.footer_button} ${style.button_Bottom}`}>
                    <Button name="Preview" menuLength={props.menuLength} customFeatureCheck={props.customFeatureCheck} recentCheck={props.recentCheck} planRoomLinkData={props.planRoomLinkData} planRoomUrl={props.planRoomUrl} formValidation={props.formValidation} menuUrl={props.menuUrlVal} customfeatureurl={props.customFeature} recentUrl={props.recentUrl} validate={props.validation} popup={props.popupStatus} loadingActive={props.loading} inputValue={props.data} apiData={props.api} token={props.token}/>
                    <Button name="Publish" menuLength={props.menuLength} customFeatureCheck={props.customFeatureCheck} recentCheck={props.recentCheck} planRoomLinkData={props.planRoomLinkData} planRoomUrl={props.planRoomUrl} formValidation={props.formValidation} menuUrl={props.menuUrlVal} customfeatureurl={props.customFeature} recentUrl={props.recentUrl} validate={props.validation} popup={props.popupStatus} loadingActive={props.loading} inputValue={props.data} apiData={props.api} token={props.token} />
                </div>
            </div>
        </div>
    );
}
export default PreviewAndPublish;