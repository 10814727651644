function ApiUrl() {
  let api = {
    apiUrl: "https://api.tenantcubelistings.com",
    // apiUrl: "https://website-prod-elb-1707093701.ca-central-1.elb.amazonaws.com", // production
    // image:
    //   "http://website-tc-uploads-dev.s3-website.ca-central-1.amazonaws.com",
    previewUrl: ".preview.tenantcubelistings.com",
  };

  const fullDomain = window.location.hostname;
  const domainParts = fullDomain.split(".");
  const subDomain = domainParts[0];

  if (subDomain === "localhost") {
    api.apiUrl = "https://dev-api.tenantcubelistings.com";
    api.previewUrl = ".dev-preview.tenantcubelistings.com";
    console.log("LOCAL");
  } else if (subDomain === "dev") {
    api.apiUrl = "https://dev-api.tenantcubelistings.com";
    api.previewUrl = ".dev-preview.tenantcubelistings.com";
    console.log("DEV");
  } 
  

  console.log("API : ", api);
  console.log("HOST NAME   :", window.location.hostname);

  return api;
}
export default ApiUrl;
