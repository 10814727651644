import React from "react";
import commonStyle from '../../Common/CommonStyle.module.css';

const Input = React.forwardRef((props, ref) => {
    return (
        <div className={commonStyle.title_url}>
            {
                props.style == "bn" || props.style == "f" || props.style == "pr" || props.style == "fo" ?
                    (
                        props.value ?
                            <input ref={ref} onChange={props.onChange} value={props.value} placeholder={props.placeHolder} type={props.type} className={commonStyle.bn} /> :
                            <input ref={ref} onChange={props.onChange} placeholder={props.placeHolder} type={props.type} className={commonStyle.bn} />
                    )
                    : ""
            }
            {
                props.style == "sm" ?
                    (
                        props.value ?
                            <input ref={ref} onChange={props.onChange} value={props.value} placeholder={props.placeHolder} type={props.type} className={commonStyle.sm} /> :
                            <input ref={ref} onChange={props.onChange} placeholder={props.placeHolder} type={props.type} className={commonStyle.sm} />
                    )
                    : ""}
        </div>
    );
});
export default Input;