import React, { useEffect, useState } from "react";
import commonStyle from '../../Common/CommonStyle.module.css';
function Search(props) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(!open)
        props.data({ show_search: !open })
    }
    useEffect(()=>{
        setOpen(props.api.show_search)
    },[props.api.show_search])
    return (
        <div className={commonStyle.card}>
            <div className={commonStyle.card_border}>
                <div className={`${commonStyle.row} ${commonStyle.row_space}`}>
                    <h4>Search</h4>
                    {open ?
                        <div onClick={handleOpen} className={commonStyle.toggle_on}>
                            <div className={commonStyle.toggle_right}></div>
                        </div> :
                        <div onClick={handleOpen} className={commonStyle.toggle_off}>
                            <div className={commonStyle.toggle_left}></div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
export default Search;