import React, { useEffect, useRef, useState } from "react";
import commonStyle from '../../Common/CommonStyle.module.css';
import Input from "../../Common/CommonComponents/Input";
import style from "./Feature.module.css";
function Feataure(props) {
    const [open, setOpen] = useState(false);
    const [rentalCheck, setRentalCheck] = useState(false);
    const [maintenanceCheck, setMaintenanceCheck] = useState(false);
    const [tenantCheck, setTenantCheck] = useState(false);
    const [recentNewsCheck, setRecentNewsCheck] = useState(false);
    const [customCheck, setcustomCheck] = useState(false);
    const [recentUrlValidation, setRecentUrlValidation] = useState();
    const [customUrlValidation, setCustomUrlValidation] = useState();
    const showRentalRef = useRef();
    const showMaintanenceRef = useRef();
    const showTenantRef = useRef();
    const showcustomRef = useRef();
    const showRecentRef = useRef();
    const featureTitleRef = useRef();
    const featureDescRef = useRef();
    const featureLinkRef = useRef();
    const recentNews = useRef();
    const [featureTitle, setFeatureTitle] = useState('');
    const [featureDesc, setFeatureDesc] = useState('');
    const [featureLink, setFeatureLink] = useState('');
    const [sectionRecentNews, setRecentNews] = useState('');
    const handleCheck = (checkParam) => {
        if (checkParam == "rental") {
            setRentalCheck(!rentalCheck);
        }
        if (checkParam == "maintanence") {
            setMaintenanceCheck(!maintenanceCheck);
        }
        if (checkParam == "tenant") {
            setTenantCheck(!tenantCheck);
        }
        if (checkParam == "recent") {
            setRecentNewsCheck(!recentNewsCheck);
            if(recentNewsCheck){
                props.errorUrl({recentNewsUrl:true})
            }
            if(recentNewsCheck==true){
                setRecentNews('')
                props.data({
                    recentNewsLink: "",
                })
            }
        }
        if (checkParam == "custom") {
            setcustomCheck(!customCheck);
            if(customCheck){
                props.errorUrl({custom_url:true})
            }
            if(customCheck==true){
                setFeatureTitle('')
                setFeatureDesc('')
                setFeatureLink('')
                props.data({
                    custom_feature: JSON.stringify({
                        heading: "",
                        subHeading: "",
                        anchorHref: "",
                    }
                    )
                })
            }
        }
        props.data({ show_feature_application: showRentalRef.current.checked, show_feature_request: showMaintanenceRef.current.checked, show_feature_feedback: showTenantRef.current.checked, show_feature_custom: showcustomRef.current.checked,
            show_feature_news: showRecentRef.current.checked });
    }
    useEffect(()=>{
        props.errorUrl({recentCheck:recentNewsCheck,customCheck:customCheck})
    },[customCheck,recentNewsCheck])
    
    useEffect(()=>{
        if(props.api.custom_feature){
    const sectionTwoData = JSON.parse(props.api.custom_feature);
        setFeatureTitle(sectionTwoData.heading)
        setFeatureDesc(sectionTwoData.subHeading)
        setFeatureLink(sectionTwoData.anchorHref)
    }
    },[props.api.custom_feature])
   
    useEffect(()=>{
    setRecentNews(props.api.recentNewsLink)
    },[props.api.recentNewsLink])
    const handleOpen = () => {
        setOpen(!open)
        props.data({ show_features: !open })
    }
    useEffect(()=>{
        setOpen(props.api.show_features)
    },[props.api.show_features])
   
    const handleChanges = () => {
        setFeatureTitle(featureTitleRef.current.value)
        setFeatureDesc(featureDescRef.current.value)
        setFeatureLink(featureLinkRef.current.value)
        props.data({
            custom_feature: JSON.stringify({
                heading: featureTitleRef.current.value,
                subHeading: featureDescRef.current.value,
                anchorHref: featureLinkRef.current.value,
            }
            )
        })
    if(featureLinkRef){
        setCustomUrlValidation(urlChecker(featureLinkRef.current.value))
        props.errorUrl({custom_url:urlChecker(featureLinkRef.current.value),recentCheck:recentNewsCheck,customCheck:customCheck})
    }
    }
    const handleChangesRecentNews = () => {
    setRecentNews(recentNews.current.value)
        props.data({
            recentNewsLink: 
               recentNews.current.value,
        
        })
    if(recentNews){
        setRecentUrlValidation(urlChecker(recentNews.current.value))
        props.errorUrl({recentNewsUrl:urlChecker(recentNews.current.value),recentCheck:recentNewsCheck,customCheck:customCheck})
    }
    }
    useEffect(()=>{
        setcustomCheck(props.api.show_feature_custom)
    },[props.api.show_feature_custom])
    useEffect(()=>{
        setRecentNewsCheck(props.api.show_feature_news)
    },[props.api.show_feature_news])
    const urlChecker=(str)=>
{
 const regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        if (regexp.test(str))
        {
          return true;
        }
        else
        {
          return false;
        }
}
    return (
        <div className={commonStyle.card}>
            <div className={commonStyle.card_border}>
                <div className={`${commonStyle.row} ${commonStyle.row_space}`}>
                    <h4>Feature</h4>
                    {open ?
                        <div onClick={handleOpen} className={commonStyle.toggle_on}>
                            <div className={commonStyle.toggle_right}></div>
                        </div> :
                        <div onClick={handleOpen} className={commonStyle.toggle_off}>
                            <div className={commonStyle.toggle_left}></div>
                        </div>
                    }
                </div>
                {open ?
                    <div className={`${commonStyle.border} ${commonStyle.padding_bottom}`}>
                        <div>
                            <div className={commonStyle.checkbox_div}>
                               <div className={commonStyle.feature_checkbox_label}>
                               <label className={commonStyle.checkbox_label}>Display Rental Application?</label>
                               </div>
                                <div className={commonStyle.checkbox_rent}>
                                    <input type="checkbox"  defaultChecked={props.api.show_feature_application } value={rentalCheck} ref={showRentalRef} onChange={() => handleCheck("rental")} className={commonStyle.checkbox} />
                                </div>
                            </div>
                            <div className={commonStyle.checkbox_div}>
                            <div className={commonStyle.feature_checkbox_label}>
                                <label className={commonStyle.checkbox_label}>Display Maintenance Request?</label>
                               </div>
                                <div className={commonStyle.checkbox_main}>
                                    <input type="checkbox" defaultChecked={props.api.show_feature_request } value={maintenanceCheck} ref={showMaintanenceRef} onChange={() => handleCheck("maintanence")} className={commonStyle.checkbox} />
                                </div>
                            </div>
                            <div className={commonStyle.checkbox_div}>
                            <div className={commonStyle.feature_checkbox_label}>
                                <label className={commonStyle.checkbox_label}>Display Tenant Feedback?</label>
                                </div>
                                <div className={commonStyle.checkbox_tenant}>
                                    <input type="checkbox"  defaultChecked={props.api.show_feature_feedback } value={tenantCheck} ref={showTenantRef} onChange={() => handleCheck("tenant")} className={commonStyle.checkbox} />
                                </div>
                            </div>
                            <div className={commonStyle.checkbox_div}>
                            <div className={commonStyle.feature_checkbox_label}>
                                <label className={commonStyle.checkbox_label}>Display Recent News?</label>
                               </div>
                                <div className={commonStyle.checkbox_recent}>
                                    <input type="checkbox"  defaultChecked={props.api.show_feature_news } value={recentNewsCheck} ref={showRecentRef} onChange={() => handleCheck("recent")} className={commonStyle.checkbox} />
                                </div>
                            </div>
                            {recentNewsCheck?
                        <div className={`${style.padding_letf} ${commonStyle.space_between}`}>
                            <div>
                                <div className={`${commonStyle.padding_left} ${commonStyle.padding_top}`}>
                               
                                <div className={commonStyle.title}>
                                <label>Recent News Link</label>
                                    <Input
                                        placeHolder="Link"
                                        label='Display Section Two Web Link?'
                                        ref={recentNews}
                                        type="text"
                                        onChange={handleChangesRecentNews}
                                        style="f"
                                        value={sectionRecentNews}
                                    />
                                    {recentUrlValidation==false?
                                        <label className={commonStyle.error}>Enter valid recent news url</label>:""}
                                </div>
                                </div>
                            </div>
                        </div>:""}
                            <div className={commonStyle.checkbox_div}>
                            <div className={commonStyle.feature_checkbox_label}>
                                    <label className={commonStyle.checkbox_label}>Display Custom Feature?</label>
                                  </div>
                                    <div className={commonStyle.checkbox_tenant}>
                                        <input type="checkbox"  defaultChecked={props.api.show_feature_custom  } value={customCheck} ref={showcustomRef} onChange={() => handleCheck("custom")} className={commonStyle.checkbox} />
                                    </div>
                                </div>
                        </div>
                        
                        {customCheck?
                        <div className={`${style.padding_letf} ${commonStyle.space_between}`}>
                            <div>
                                <div className={`${commonStyle.padding_left} ${commonStyle.padding_top}`}>
                                <div className={commonStyle.title}>
                                    <label>Custom Feature Title</label>
                                    <Input
                                        placeHolder="Title"
                                        label='Display Section Two Title?'
                                        ref={featureTitleRef}
                                        type="text"
                                        onChange={handleChanges}
                                        value={featureTitle}
                                        style="f"
                                    />
                                </div>

                                <div className={commonStyle.title}>
                                <label>Custom Feature Description</label>
                                    <Input
                                        placeHolder="Description"
                                        label='Display Section Two Description?'
                                        ref={featureDescRef}
                                        type="description"
                                        onChange={handleChanges}
                                        value={featureDesc}
                                        style="f"
                                    />
                                </div>
                                <div className={commonStyle.title}>
                                <label>Custom Feature Link</label>
                                    <Input
                                        placeHolder="Link"
                                        label='Display Section Two Web Link?'
                                        ref={featureLinkRef}
                                        type="text"
                                        onChange={handleChanges}
                                        value={featureLink}
                                        style="f"
                                    />
                                    {customUrlValidation==false?
                                        <label className={commonStyle.error}>Enter valid feature url</label>:""}
                                </div>
                                </div>
                            </div>
                        </div>:""}
                    </div>
                    : ""}
            </div>
        </div>
    );
}
export default Feataure;