import React from "react";
import style from './Header.module.css';
import commonStyle from '../../Common/CommonStyle.module.css';
import history from "../../Route/history";
import Logout from '../../../Images/logout.png'
function Header() {
    const handleClick=()=>{
        history.push("/");
        history.go();
        localStorage.clear()
    }
    return (
        <div className={`${commonStyle.center_header_padding} ${commonStyle.center_header} ${commonStyle.header}  ${style.constant_header}`}>
            <div>
                <h1 className={style.title}>Portal Setting</h1>
            </div>
            <div>
                <button className={commonStyle.logout_style} onClick={handleClick}>
                    <img src={Logout} width="20px" /> Logout
                </button>
            </div>
        </div>
    );
}
export default Header;