import { useEffect, useState } from "react";
import ApiUrl from "../ApiUrl/ApiUrl";
const GetApi = (setLoad) => {
  const [responseData, setResponseData] = useState("");
  const apiUrl = ApiUrl();
  const id = localStorage.getItem("accountId");
  useEffect(() => {
    if (setLoad) {
      setLoad(true);
    }
    fetch(`${apiUrl.apiUrl}/portal/single/${id}`)
      .then((result) => result.json())
      .then((getValue) => {
        setResponseData(getValue.portal ? getValue.portal : {});
      })
      .finally(() => {
        setLoad(false);
      });
  }, []);
  return responseData;
};
export default GetApi;
